import React from 'react';
import PropTypes from 'prop-types';
import { setCookie } from '../utils/cookies';
import AuthPage from '../components/auth-page';

const Auth = ({ success, error, from, code }) => {
  if (success) setCookie('auth_key', code, { path: '/', sameSite: 'Strict' });
  return <AuthPage success={success} error={error} from={from} />;
};

// Validate the code on the server side
export async function getServerSideProps(ctx) {
  const { code, from } = ctx.query;

  // No code provided
  if (!code)
    return { props: { success: false, error: false, from: from || '/' } };

  // Success
  if (code === process.env.PASSCODE_TEXT)
    return {
      props: {
        success: true,
        error: false,
        from: from || '/',
        code: process.env.PASSCODE_TEXT
      }
    };

  // Failure
  return { props: { success: false, error: true, from: from || '/' } };
}

Auth.propTypes = {
  success: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  from: PropTypes.string.isRequired,
  code: PropTypes.string
};
Auth.defaultProps = {
  code: null
};

export default Auth;
