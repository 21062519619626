import styled from '@emotion/styled';
import { css } from '@emotion/react';

const Page = styled.div`
  position: relative;
  background: #eaeaea;
  width: 100%;
  min-height: 100vh;
`;

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #2b2b2b;
`;

const Message = styled.div`
  padding: 1em;
  border: 0.1em solid #929292;
  background: transparent;
  margin-bottom: 1rem;
  font-size: 1.3rem;
  border-radius: 0.3rem;
  text-align: center;

  ${props =>
    props.success &&
    css`
      background: #c3f3c3;
      border-color: #acdaac;
    `}

  ${props =>
    props.error &&
    css`
      background: #f3c3c3;
      border-color: #daacac;
    `}
`;

const MessageText = styled.span`
  display: block;
  margin-bottom: 0.6em;

  b,
  a {
    font-weight: 500;
  }
  a {
    color: #2738be;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const Form = styled.form`
  padding: 1.7rem;
  background: #ffffff;
  border-radius: 0.3rem;
  display: flex;
  box-shadow: 0.2rem 0.2rem 0.5rem #d0d0d0;
`;

const Input = styled.input`
  padding: 1rem;
  border-radius: 0.3rem;
  border: 0.1em solid #989898;
  color: inherit;
  margin-right: 1rem;
  flex-grow: 1;

  ${props =>
    props.hidden &&
    css`
      display: none;
    `}
`;

const Button = styled.button`
  padding: 1rem;
  border-radius: 0.3rem;
  border: 0.1em solid #2298d1;
  color: #ffffff;
  background: #2298d1;
  transition: background-color 0.2s;
  text-transform: uppercase;

  &:hover {
    background: #1887bd;
    border-color: #1887bd;
  }
`;

export default { Page, Wrapper, Message, MessageText, Form, Input, Button };
