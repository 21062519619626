import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Styles from './auth-page.styles';

const AuthPage = ({ success, error, from }) => {
  // Redirect the user on success
  useEffect(() => {
    if (success) window.location.replace(from);
  }, [success]);

  return (
    <Styles.Page>
      <Styles.Wrapper>
        {(success || error) && (
          <Styles.Message role='alert' success={success} error={error}>
            {success && (
              <>
                <Styles.MessageText>
                  Success! Redirecting you to <b>{from}</b>.
                </Styles.MessageText>
                <Styles.MessageText>
                  If this takes too long, please <a href={from}>click here</a>.
                </Styles.MessageText>
              </>
            )}
            {error && (
              <Styles.MessageText>
                The login code entered is incorrect.
              </Styles.MessageText>
            )}
          </Styles.Message>
        )}
        <Styles.Form>
          <Styles.Input
            name='code'
            autoComplete='off'
            placeholder='Login code'
          />
          <Styles.Input name='from' value={from} readOnly hidden />
          <Styles.Button type='submit'>Go</Styles.Button>
        </Styles.Form>
      </Styles.Wrapper>
    </Styles.Page>
  );
};

AuthPage.propTypes = {
  success: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  from: PropTypes.string.isRequired
};

export default AuthPage;
